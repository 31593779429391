import React, {Suspense, useEffect, useState, useRef} from "react";
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    NavItem,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Row,
} from "reactstrap";
import {useNavigate, useLocation, Link, Outlet} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import ProfileImage from "../../components/core/ProfileImage";
import useAppContext from "../../hooks/useAppHook";
import useObjectContext from "../../hooks/useObjectHook";
import {Search} from "../../components/SearchDashboard/Search";
import {DropDownFilterPanel} from "../../components/DropDownFilterPanel/DropDownFilterPanel";
import {isCalendarEnabled, isUserComplate, querystring, visibility} from "../../utils/Utility";
import {getInitial} from "../../utils/StringUtil";
import UserProfile from "../../components/User/UserProfile";
import {EventEmitter} from "../../services/event";
import FullViewIcon from "../../assets/img/reduce.svg"
/* eslint-disable */
import {BehaviourContextProvider, ObjectContextProvider, CategoryContextProvider} from "../../context/AppStore";
import useBehaviourContext from "../../hooks/useBehaviourHook";
import PropTypes from "prop-types";
import FormPasswordReset from "../../components/User/changePassword";
import Loading from "../../components/core/Loading";
import {callAxiosServiceWithParam} from "../../services/callAxios";
import RenderIntegrationItem, {AppCategories} from "../../components/ExternalApps/AppInstaller";
import {InfoModal} from "../../components/Dashboard/InfoModal"
import UploadMeeting from "../../components/modalComponent/UploadMeeting";
import LiveMeeting from "../../components/modalComponent/LiveMeeting";
import FilterPanel from "../../components/core/Filters/FilterPanel";
import {SaveSearchModal} from "../../components/Dashboard/SaveSearchModal";


const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {};

function DashBoard(props) {
    const {showSearchPanel, setShowSearchPanel} = useBehaviourContext();
    return (
        <NavItem style={{marginTop: "12px"}}>
            <reactstrapNavLink href="#" onClick={() => setShowSearchPanel(!showSearchPanel)}>
                <i className="nav-icon fa fa-dashboard"></i>
                Dashboard
            </reactstrapNavLink>
        </NavItem>
    );
}

function ProfileMenu(props) {

    return (
        <UncontrolledDropdown className="meetingmenu" direction="down" active>
            <DropdownToggle nav>
                <i className="meeting-first-row-action"/>
            </DropdownToggle>
            <DropdownMenu end className="sub-menu">
                <DropdownItem onClick={(e) => {
                }} className="sharedto">
                    <i className="sharedto-icon"></i>
                    Share with
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

function SearchFilter(props) {
    const {expandFilterPanel, setExpandFilterPanel} = useBehaviourContext();
    return (
        <NavItem style={{marginTop: "12px"}}>
            <reactstrapNavLink
                href="#"
                onClick={() => setExpandFilterPanel(!expandFilterPanel)}
            >
                <i className="nav-icon fa fa-search"></i>
                Filters
            </reactstrapNavLink>
        </NavItem>
    );
}

function UserCreationLink(props) {
    return (
        <>
            <reactstrapNavLink to="" id="userProfile" href="#" className="nav-link">
                Profile
            </reactstrapNavLink>
            <UserProfile targetId="userProfile"></UserProfile>
        </>
    );
}

function Profile(props) {

    const {user, setPhotoUrl, photoUrl, setUser, mode} = useAppContext();
    const {setHideMeetingPanel, setExtraLeftPanel} = useBehaviourContext();
    const [isOpen, setIsOpen] = useState(false);
    const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
    const [bioMetricUrl, setBiometricUrl] = useState(user.bioMetricUrl);
    const [toggleError, setToggleError] = useState(false)


    useEffect(() => {
        setHideMeetingPanel(false);
        setExtraLeftPanel("");
    }, [props.active])

    async function fetchData() {
        let url = user?.customer?.id + "/biometrics/" + user?.id;
        let filePath = await callAxiosServiceWithParam(
            "users/signedurl",
            "filepath=" + encodeURIComponent(url)
        )
        if (!filePath || filePath.data == '') {
            return Promise.resolve("");
        } else {
            //Uncaught (in promise) TypeError: Cannot assign to read only property 'bioMetricUrl' of object '#<Object>'
            //Need to call setUser
            // user.bioMetricUrl = url;
            setBiometricUrl(filePath.data)
            return Promise.resolve("");
        }
    }

    const toggle = (event, newBioMetricUrl) => {
        if (bioMetricUrl || newBioMetricUrl) {
            if (newBioMetricUrl) {
                fetchData().then(e => {
                    setIsOpen(false);
                })
            } else {
                setIsOpen(!isOpen);
            }
        } else {
            setIsOpen(!isOpen);
        }
    }

    const toggleOnbarding = (event, show = false) => {
        if (!isUserComplate(user) || !isCalendarEnabled(user)) {
            setIsOnboardingOpen(true);
        } else {
            setIsOnboardingOpen(show);
        }
    }
    const [isPasswordOpen, setIsPasswordOpen] = useState(false);
    const togglePassword = () => setIsPasswordOpen(!isPasswordOpen);

    const [isInfoModalOpen, setIsInfoModalOpen] = useState(querystring("panel"));
    const toggleInfo = () => setIsInfoModalOpen(!isInfoModalOpen);

    useEffect(() => {
        if (user && !photoUrl) {
            callAxiosServiceWithParam(
                "users/signedurl",
                "filepath=" + encodeURIComponent(photoUrl)
            )
                .then((filePath) => {
                    setPhotoUrl(filePath.data);
                })
                .catch((e) => {
                });
        }
    }, [photoUrl]);
    useEffect(() => {
        if (bioMetricUrl && !bioMetricUrl.startsWith("http")) {
            fetchData().then(e => {
            })
        } else if (user.bioMetricUrl && !user.bioMetricUrl.startsWith("http")) {
            fetchData().then(e => {
            })
        }

        if (!isOnboardingOpen && isUserComplate(user) && isCalendarEnabled(user)) {
            toggleOnbarding(null, false)
        } else {
            toggleOnbarding(null, true)
        }
    }, [user, user.bioMetricUrl]);

    // useEffect(() => {
    //      if (registeredApps) {
    //          registeredApps.forEach((provider) => {
    //              if (provider.category == AppCategories.MEETINGS && provider.status == AppProviderStatus.INSTALLED)  {
    //                  setIsConferenceDone(true)
    //              }
    //          })
    //      }
    //  });

    return (
        <div>
            <UncontrolledDropdown nav direction="right" tag="div">
                <DropdownToggle nav>
                    <ProfileImage
                        className="rounded-img1"
                        photoUrl={photoUrl}
                        text={getInitial(user.firstName, user.lastName)}
                    />
                </DropdownToggle>
                <DropdownMenu left="true" className="sub-menu">
                    <DropdownItem id="btnUserProfile" onClick={toggle} type={"link"}>
                        <i className="sharedto"></i>
                        Profile
                    </DropdownItem>
                    <DropdownItem
                        id="btnChangePassword"
                        onClick={togglePassword}
                        type={"link"}
                    >
                        <i className="sharedto"></i>
                        Change Password
                    </DropdownItem>
                    <DropdownItem onClick={(e) => props.onLogout(e)}>
                        <i className="sharedto"></i>
                        Logout
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <ModalProfile
                isOpen={isOpen}
                toggle={toggle}
                toggleError={toggleError}
                bioMetricUrl={bioMetricUrl}
            ></ModalProfile>
            <ModalInfo
                isOpen={isInfoModalOpen}
                toggle={toggleInfo}
            ></ModalInfo>
            {/*<InitialOnboardingModal*/}
            {/*    isOpen={isOnboardingOpen}*/}
            {/*    toggle={toggleOnbarding}*/}
            {/*    toggleError={toggleError}*/}
            {/*    bioMetricUrl={bioMetricUrl}*/}
            {/*></InitialOnboardingModal>*/}
            <ModalPassword
                isOpen={isPasswordOpen}
                toggle={togglePassword}
            ></ModalPassword>
        </div>
    );
}

function ModalInfo(props) {
    const [infoModalOpen, setInfoModalOpen] = useState(false)
    const toggleSharedOpen = () => {
        props.toggle()
    };
    return (
        <Modal
            size="md"
            className="appProvider-container"
            isOpen={props.isOpen}
            toggle={props.toggle}
            placement="auto-end"
            centered={true}
            target={props.targetId}
        >
            <ModalHeader toggle={props.toggle} charCode="x"
                         style={{border: "none", display: "flex", justifyContent: "center", alignItems: "center",}}
            >
                <span style={{fontSize: "24px", padding: "20px", fontWeight: 600}}>
                    Important Tips
                </span>
            </ModalHeader>
            <ModalBody>
                <InfoModal
                    targetId="btnUserProfile"
                    toggle={props.toggle}
                    toggleError={props.toggleError}
                    bioMetricUrl={props.bioMetricUrl}
                ></InfoModal>
            </ModalBody>
            <ModalFooter style={{borderTop: "0 none"}}>
                <div style={{padding: 10}}>
                    <Button color="primary" style={{background: '#0052cc', color: '#fff', marginTop: "-30"}}
                            onClick={(e) => {
                                props.toggle();
                                window.location.href = '/#/settings?panel=5'
                            }}>Modify Settings
                    </Button>
                </div>
                {"  "}
                <div style={{padding: 10}}>
                    <Button color="primary" style={{background: '#0052cc', color: '#fff', marginTop: "-30"}}
                            onClick={(e) => {
                                toggleSharedOpen()
                            }}>Continue
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

function ModalProfile(props) {
    return (
        <Modal
            size="lg"
            className="appProvider-container"
            isOpen={props.isOpen}
            toggle={props.toggle}
            placement="auto-end"
            target={props.targetId}
        >
            <ModalHeader
                toggle={props.toggle}
                charCode="x"
                style={{paddingBottom: 0, border: "none"}}
            >
                <span style={{fontSize: "24px", paddingLeft: "20px"}}>
                    User Profile
                </span>
            </ModalHeader>
            <ModalBody>
                <UserProfile
                    targetId="btnUserProfile"
                    toggle={props.toggle}
                    toggleError={props.toggleError}
                    bioMetricUrl={props.bioMetricUrl}
                ></UserProfile>
            </ModalBody>
        </Modal>
    );
}

function ModalPassword(props) {
    let currentPassword = "";
    let newPassword = "";
    const {toggle, row} = props;
    return (
        <Modal
            size="sm"
            className="aum-list"
            isOpen={props.isOpen}
            toggle={props.toggle}
            placement="auto-end"
            target={props.targetId}
        >
            <ModalHeader toggle={props.toggle} charCode="x" className="font-24">
                <h2>
                    <b>Change Password</b>
                </h2>
            </ModalHeader>
            <ModalBody>
                <FormPasswordReset
                    targetId="btnChangePassword"
                    toggle={props.toggle}
                ></FormPasswordReset>
            </ModalBody>
        </Modal>
    );
}

function ModalConference(props) {
    return (
        <Modal
            size="xl"
            className="appProvider-container"
            isOpen={props.isOpen}
            toggle={props.toggle}
            placement="auto-end"
            target={props.targetId}
        >
            <ModalHeader
                toggle={props.toggle}
                charCode="x"
                style={{paddingBottom: 0, border: "none"}}
            >
                <span style={{fontSize: "24px", paddingLeft: "20px"}}>
                    Web Conference Integration
                </span>
            </ModalHeader>
            <ModalBody>
                {props.error && <h3 style={{color: "red"}}>Please finish integrating a web conference</h3>}
                <RenderIntegrationItem
                    category={AppCategories.MEETINGS} isOnboardingFlow={true}>
                </RenderIntegrationItem>
            </ModalBody>
        </Modal>
    );
}

function ModalLiveMeeting(props) {
    return (
        <Modal
            className="appProvider-container"
            isOpen={props.isOpen}
            toggle={props.toggle}
            placement="auto-end"
            centered={true}
        >
            <ModalHeader
                toggle={props.toggle}
                charCode="x"
                style={{paddingBottom: 0, border: "none"}}
            >
                <span style={{fontSize: "24px", paddingLeft: "20px"}}>
                    Add Rafiki NoteTaker to a Live Call
                </span>
            </ModalHeader>
            <ModalBody>
                <LiveMeeting
                    toggle={props.toggle}
                ></LiveMeeting>
            </ModalBody>
        </Modal>
    );
}


const DefaultLayout = () => {
    const navigate = useNavigate();
    const [hasCrmIntegration, setHasCrmIntegration] = useState(false);
    const [execute, setExecute] = useState(false);
    const [activeNav, setActiveNav] = useState("meeting");
    const [open, isOpen] = useState(false);
    const active = () => isOpen(!open);
    const location = useLocation();
    const {isFilterTopNav, setFilterTopNav} = useBehaviourContext();
    const {user, setUser, mode, msalInstance, navMeetId, filterData, setFilterData,} = useAppContext();
    const {setIsPlaying} = useObjectContext();
    const loading = () => <Loading></Loading>;
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggleTT = (_) => setTooltipOpen(tooltipOpen ? false : true);
    const [tooltipVLOpen, setTooltipVLOpen] = React.useState(false);
    const toggleVL = (_) => setTooltipVLOpen(tooltipVLOpen ? false : true);
    const onClickActiveNav = (active) => setActiveNav(active);
    const [licenseMsg, setLicenseMsg] = useState("");
    const [upgradeUrl, setUpgradeUrl] = useState("/#/settings?tab=5");
    const [isUploadOpen, setIsUploadOpen] = React.useState(false);
    const toggleUploadModalOpen = () => setIsUploadOpen(!isUploadOpen);
    const [isOpenFilter, setIsFilterOpen] = useState(false);
    const toggleFilter = () => setIsFilterOpen(!isOpenFilter);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filterForm, setFilterForm] = useState({});
    const [popoverOpen, setPopoverOpen] = useState(false);
    const togglePO = () => setPopoverOpen(!popoverOpen);
    const [tooltipUpgradeOpen, setTooltipUpgradeOpen] = React.useState(false);
    const toggleUpgrade = (_) => setTooltipUpgradeOpen(tooltipUpgradeOpen ? false : true);
    const [showBanner, setShowBanner] = useState(false);
    const [isSaveOpen, setIsSaveOpen] = useState(false);
    const toggleSaveOpen = () => {
        setIsPlaying(false);
        setIsSaveOpen(!isSaveOpen);
    }
    const searchRef = useRef();
    const clearSearch = () => {
        if (searchRef.current) {
            searchRef.current.clear();
        }
    }

    function isChrome() {
        return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    }

    function checkExtension(extensionId) {
        return new Promise((resolve) => {
            if (!isChrome()) {
                resolve(false);
            } else {
                chrome.runtime?.sendMessage(
                    extensionId,
                    {type: "GET_VERSION"},
                    (response) => {
                        if (chrome.runtime?.lastError) {
                            resolve(false);
                        } else {
                            resolve(response);
                        }
                    }
                );
            }
        });
    }

    function withinThreeWeeks(date) {
        const threeWeeksInMilliseconds = 21 * 24 * 60 * 60 * 1000;
        const diff = Date.now() - date.getTime();
        return diff <= threeWeeksInMilliseconds;
    }

    useEffect(() => {
        async function checkExtensionAndUpdateState() {
            const response = await checkExtension(process.env.REACT_APP_CHROME_EXTENSION_ID);
            if (!response || response.version !== process.env.REACT_APP_CHROME_EXTENSION_VERSION &&
                withinThreeWeeks(new Date(process.env.REAACT_APP_CHROME_EXTENSION_DATE))) {
                setShowBanner(true);
                setTimeout(() => {
                    setShowBanner(false);
                }, 5000); // Hide the banner after 5 seconds
            }
        }

        checkExtensionAndUpdateState();
    }, []);

    const [liveMeetOpen, liveMeetSetOpen] = useState(false);
    const toggleLM = () => liveMeetSetOpen(!liveMeetOpen);
    // const [enableLiveMeetOpen, setEnableLiveMeetOpen] = useState(true);
    // const {meetings} = useObjectHook();
    const [calendar, setCalendar] = useState(false);
    useEffect(() => {
        if (user) {
            const myCalendar = user?.providers?.filter((e) => {
                return (
                    (e?.provider?.toLowerCase() === "google" || e?.provider?.toLowerCase() === "microsoft") &&
                    e?.service?.toLowerCase() === "calendar"
                )
            });
            if (myCalendar && myCalendar?.length > 0) {
                setCalendar(myCalendar[0]?.accessToken);
            }
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            let start = new Date(user.trialStarted);
            let end = new Date(user.trialEnded);
            let now = new Date();
            const date1utc = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
            const date2utc = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
            let temp = (date2utc - date1utc) / (1000 * 60 * 60 * 24);
            if (user.license === "Trial" && (now > end || now < start)) {
                setLicenseMsg('Trial has expired. Upgrade your account.')
            } else if (user.license === "Trial") {
                setLicenseMsg('Days left on Trial: ' + temp);
            } else
                setLicenseMsg("Upgrade to Premium");
            if (user.licenseConfig?.bot && user.allowNotetaker) {
                setUpgradeUrl("/#/settings?tab=6");
            } else {
                setUpgradeUrl("/#/settings?tab=6");
            }
            if (user?.providers) {
                const crmProviders = user.providers.filter(provider =>
                    provider.service.toLowerCase() === 'crm' ||
                    (user.customer && user.customer.providers &&
                        user.customer.providers.some(p => p.service.toLowerCase() === 'crm'))
                );
                setHasCrmIntegration(crmProviders.length > 0);
            }
        }
    }, [user]);

    async function signOut(e) {
        e.preventDefault();
        window.Intercom("shutdown");
        localStorage.clear();
        const url = `${process.env.REACT_APP_REPOSITORY_URL}/logout`;
        fetch(url, {
            method: "get",
            credentials: "include",
        })
            .then(function (response) {
                if (msalInstance) {
                    msalInstance.logout();
                } else {
                    setUser(null);
                    navigate("/login");
                    window.location.reload();
                }
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    useEffect(() => {

        if (navMeetId) {
            const body = document.querySelector('.app-body');
            body.scrollTo(0, 0)
        }
    }, [navMeetId]);


    const clearSearchCallBack = () => {
        setFilterForm(null);
        setIsFiltered(false);
        EventEmitter.dispatch("search_cleared", true)
    }

    const clearFilter = (e) => {
        e.preventDefault();
        clearSearch();
        setFilterForm(null);
        setIsFiltered(false);
        setFilterTopNav(false);
        setFilterData({});
        if (setIsFiltered) {
            setIsFiltered(false);
        }
    };
    const openIntercomChat = () => {
        if (window.Intercom) {
            window.Intercom('show');
        }
    };

    const loadingDiv = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

    return (
        <div className="app">
            {showBanner && (
                <div className="banner">
                    <span>Update your&nbsp;&nbsp;</span>
                    <a href={`https://chrome.google.com/webstore/detail/${process.env.REACT_APP_CHROME_EXTENSION_ID}`}
                       target="_blank" rel="noreferrer">Rafiki Chrome Extension</a>
                    <span>&nbsp;&nbsp;</span>
                    <p>to the latest version</p>
                </div>
            )}
            <CategoryContextProvider>
                <ObjectContextProvider>
                    <BehaviourContextProvider>
                        <div style={{height: '60px', width: '100%'}}></div>
                        <div
                            className={`header ${
                                (location?.pathname === "/meetings" || location?.pathname === "/meetings-full") ? " meetings-open" : ""
                            }`}
                            style={{display: "flex", justifyContent: "space-between"}}
                        >
                            <div style={{display: "flex", alignItems: "center", gap: "0px", height: "100%"}}>
                                <div className={"subheader"}>
                                    <Link to="/home">
                                        <div className="logo"/>
                                    </Link>
                                </div>
                                <div className="nav-search-outer">
                                    <Search clearSearchCallBack={clearSearchCallBack} ref={searchRef}></Search>
                                </div>
                                {location?.pathname === "/meetings" || location?.pathname === "/meetings-full" ? (
                                    <DropDownFilterPanel/>) : <></>}
                                {location?.pathname === "/meetings" || location?.pathname === "/meetings-full" ?
                                    <div className={isFiltered ? "more activeMoreFilter" : "more"}
                                         onClick={() => {
                                             toggleFilter();
                                         }} id="btnFilter">
                                        {<UncontrolledTooltip placement="bottom" target="btnFilter">
                                            Additional Search Filters
                                        </UncontrolledTooltip>}
                                    </div> : <></>}

                                {location?.pathname === "/meetings" || location?.pathname === "/meetings-full" && user?.licenseConfig?.upload ?
                                    <div className="uploadMeeting"
                                         style={{
                                             marginLeft: 5, backgroundRepeat: "no-repeat", backgroundSize: "16px 16px",
                                             cursor: "pointer", objectFit: "contain", marginRight: "2px"
                                         }}
                                         onClick={() => {
                                             toggleUploadModalOpen();
                                         }} id="upload">
                                        {<UncontrolledTooltip placement="bottom" target="upload">
                                            Upload Recording
                                        </UncontrolledTooltip>}
                                        <Modal isOpen={isUploadOpen} toggle={toggleUploadModalOpen} centered={true}
                                               contentClassName="snippet-modal" backdrop="static">
                                            <UploadMeeting targetId="upload"
                                                           isOpen={isUploadOpen}
                                                           toggle={toggleUploadModalOpen}>
                                            </UploadMeeting>
                                        </Modal>
                                    </div> : <></>}
                                {Object.keys(filterData).length !== 0 &&
                                    (location?.pathname === "/meetings" || location?.pathname === "/meetings-full") &&
                                    <> <Button color={"dark"} className={"topBarButton hide-1400"}
                                               id={"saveLink"} onClick={toggleSaveOpen}>
                                        SAVE
                                    </Button>
                                        <div className="filterSave show-1400" onClick={toggleSaveOpen}
                                             id={"saveLink"}></div>
                                        <UncontrolledTooltip placement="bottom" target="saveLink">
                                            Save Filters & Setup Alert
                                        </UncontrolledTooltip>
                                        <SaveSearchModal targetId="saveLink" isOpen={isSaveOpen}
                                                         toggle={toggleSaveOpen}/>

                                        <Button color={"dark"} className={"topBarButton hide-1400"}
                                                id={"clearSearch"} onClick={clearFilter}>
                                            CLEAR
                                        </Button>
                                        <div className="filterClear show-1400" onClick={clearFilter}
                                             id={"clearSearch"}></div>
                                        <UncontrolledTooltip placement="bottom" target="clearSearch">
                                            Clear all Filters
                                        </UncontrolledTooltip>
                                    </>}
                            </div>
                            <Row style={{
                                display: "flex", flexWrap: "nowrap", marginRight: "1rem", marginLeft: "4px",
                                alignItems: "center", justifyContent: "flex-end", gap: "5px", overflow: 'hidden'
                            }}>
                                {/* {!calendar &&
                                    <div>
                                        <Button className={"topBarCalendar hideCalendarButton"}
                                                id={"integrateCalendar"}
                                                style={{width: "fit-content !important", padding: "0px 2px"}}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = "/#/settings?tab=3"
                                                }}>
                                            <span className="hideText">INTEGRATE </span>
                                            <img src={"../../assets/img/calendar.svg"} alt={"calendar}"}
                                                 style={{width: 16, height: 20, marginLeft: "5px"}}
                                            />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="integrateCalendar">
                                            Integrate Calendar
                                        </UncontrolledTooltip>
                                    </div>
                                } */}
                                <div>
                                    {(user.license === "Trial" || user.license === "Free") &&
                                        <>
                                            <Button id="upgradeHome" type="button" color={'dark'}
                                                    className={"topBarCalendar hideCalendarButton upgradeButton"}
                                                    style={{width: "fit-content !important", padding: "0px 2px"}}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href = upgradeUrl
                                                    }}
                                            >
                                                <span className="hideText">UPGRADE </span>
                                                <img src={"../../assets/img/upgrade.svg"} alt={"calendar}"}
                                                     style={{width: 16, height: 20, marginLeft: "5px"}}/>
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="upgradeHome">
                                                {licenseMsg}
                                            </UncontrolledTooltip>
                                        </>
                                    }
                                </div>
                                {location?.pathname === "/meetings" ?
                                    <div style={{
                                        margin: "0px 10px 0px 5px", float: "right",
                                        cursor: "pointer", objectFit: "contain", display: "flex",
                                        justifyContent: "flex-end",
                                    }}><img alt="" id={"switch"}
                                            style={{backgroundSize: "18px 18px", backgroundRepeat: "no-repeat",}}
                                            onClick={e => navigate("/meetings-full")} src={FullViewIcon}/>
                                        <UncontrolledTooltip placement="left-start" target="switch">
                                            Switch to 3-Pane View
                                        </UncontrolledTooltip>
                                    </div> :
                                    location?.pathname === "/meetings-full" ?
                                        <div style={{
                                            margin: "0px 10px 0px 5px", float: "right",
                                            cursor: "pointer", objectFit: "contain", display: "flex",
                                            justifyContent: "flex-end",
                                        }}><img alt="" id={"switch"}
                                                style={{backgroundSize: "18px 18px", backgroundRepeat: "no-repeat",}}
                                                onClick={e => navigate("/meetings")}
                                                src={"../../assets/img/fullview_grey.svg"}/>
                                            <UncontrolledTooltip placement="left-start" target="switch">
                                                Switch to Full-Pane View
                                            </UncontrolledTooltip>
                                        </div> :
                                        <></>}
                            </Row>
                        </div>
                        <ModalLiveMeeting toggle={toggleLM} isOpen={liveMeetOpen}></ModalLiveMeeting>
                        <FilterPanel
                            isFiltered={isFiltered}
                            setIsFiltered={setIsFiltered}
                            isOpen={isOpenFilter}
                            toggle={toggleFilter}
                            filterForm={filterForm}
                            setFilterForm={setFilterForm}
                            clearSearch={clearSearch}
                        />
                        <div className="app-body">
                            <div
                                className="sidebar"
                                style={{
                                    display: 'flex', flexDirection: 'column', zIndex: 1019, minWidth: '65px',
                                    top: '60px', height: 'calc(100vh - 60px)', position: 'fixed',
                                }}
                            >
                                <ul
                                    className={open ? "active flex-column navbar-nav" : "var(--mainColor) flex-column navbar-nav"}
                                >
                                    <li
                                        className="px-2 nav-item"
                                        onClick={() => onClickActiveNav("home")}
                                    >
                                        <Link to="/home" id="home" style={{width: '60px'}}
                                              className={location?.pathname === "/home" ? "newside nav-link active " : "newside nav-link"}
                                        >
                                            <div className="nav-line">
                                                <svg className="home sidebar-icon"></svg>
                                            </div>
                                        </Link>
                                        <Tooltip anchorSelect="#home" content="Home" place="right"/>
                                    </li>
                                    <li
                                        className="px-2 nav-item"
                                        onClick={() => onClickActiveNav("grid")}
                                    >
                                        <Link to="/grid" id="gris" style={{width: '60px'}}
                                              className={location?.pathname === "/grid" ? "newside nav-link active " : "newside nav-link"}
                                        >
                                            <div className="nav-line">
                                                <svg className="grid sidebar-icon"></svg>
                                            </div>
                                        </Link>
                                        <Tooltip anchorSelect="#grid" content="Grid" place="right"/>
                                    </li>
                                    <li
                                        className="px-2 nav-item"
                                        onClick={() => onClickActiveNav("meeting")}
                                    >
                                        <Link to="/meetings" id="meetings" style={{width: '60px'}}
                                              className={location?.pathname === "/meetings" ? "newside nav-link active " : "newside nav-link"}
                                        >
                                            <div className="nav-line">
                                                <svg className="video sidebar-icon"></svg>
                                            </div>
                                        </Link>
                                        <Tooltip anchorSelect="#meetings" content="Meetings" place="right"/>
                                    </li>
                                    {hasCrmIntegration && (
                                        <>
                                            <li
                                                className="px-2 nav-item"
                                                onClick={() => onClickActiveNav("deal")}
                                            >
                                                <Link to="/deals-full" id="deals" style={{width: '60px'}}
                                                      className={location?.pathname === "/deals-full" ? "newside nav-link active " : "newside nav-link"}
                                                >
                                                    <div className="nav-line">
                                                        <svg className="deals sidebar-icon"></svg>
                                                    </div>
                                                </Link>
                                                <Tooltip anchorSelect="#deals" content="Deals" place="right"/>
                                            </li>
                                            <li
                                                className="px-2 nav-item"
                                                onClick={() => onClickActiveNav("accounts")}
                                            >
                                                <Link to="/accounts" id="accounts" style={{width: '60px'}}
                                                      className={location?.pathname === "/accounts" ? "newside nav-link active " : "newside nav-link"}
                                                >
                                                    <div className="nav-line">
                                                        <svg className="accounts sidebar-icon"></svg>
                                                    </div>
                                                </Link>
                                                <Tooltip anchorSelect="#accounts" content="Accounts" place="right"/>
                                            </li>
                                        </>
                                    )}
                                    <li
                                        className="px-2 nav-item"
                                        onClick={() => onClickActiveNav("playlists")}
                                    >
                                        <Link to="/playlists" id="playlists" style={{width: '60px'}}
                                              className={location?.pathname === "/playlists" ? "newside nav-link active " : "newside nav-link"}
                                        >
                                            <div className="nav-line">
                                                <svg className="playlists sidebar-icon"></svg>
                                            </div>
                                        </Link>
                                        <Tooltip anchorSelect="#playlists" content="Playlists" place="right"/>
                                    </li>
                                    <li
                                        className="px-2 nav-item"
                                        onClick={() => onClickActiveNav("analytics")}
                                    >
                                        <Link to="/analytics" id="analytics" style={{width: '60px'}}
                                              className={location?.pathname === "/analytics" ? "newside nav-link active " : "newside nav-link"}
                                        >
                                            <div className="nav-line">
                                                <svg className="analytics sidebar-icon"></svg>
                                            </div>
                                        </Link>
                                        <Tooltip anchorSelect="#analytics" content="Analytics" place="right"/>
                                    </li>
                                    <li
                                        className="px-2 nav-item"
                                        onClick={() => onClickActiveNav("coaching")}
                                    >
                                        <Link to="/coaching" id="coaching" style={{width: '60px'}}
                                              className={location?.pathname === "/coaching" ? "newside nav-link active " : "newside nav-link"}
                                        >
                                            <div className="nav-line">
                                                <svg className="coaching sidebar-icon"
                                                     style={{margin: '0px'}}></svg>
                                            </div>
                                        </Link>
                                        <Tooltip anchorSelect="#coaching" content="Coaching" place="right"/>
                                    </li>
                                </ul>
                                <ul className="flex-column" style={{paddingLeft: '20px', display: 'inline-flex',}}>
                                    <li className="header-bell-icon header-icon"
                                        style={{visibility: visibility(), backgroundColor: "inherit"}}>
                                        <a target="_blank"
                                           href={"https://" + "docs.getrafiki.ai/en/articles/5258794-video-help-librarys"}>
                                            <img src={"/assets/img/help.svg"} id="videolibrary"
                                                 style={{height: 24, width: 24}}/>
                                            <Tooltip anchorSelect="#videolibrary" content="Best Practices Videos"
                                                     place="right"/>
                                        </a>
                                    </li>
                                    <li className="header-bell-icon header-icon"
                                        style={{visibility: visibility(), backgroundColor: "inherit"}}>
                                        <Link to="/home" className={`nav-link`}>
                                            <img id="intercom" src={"/assets/img/intercom-2.svg"}
                                                 style={{width: 24, height: 24}}/>
                                            <Tooltip anchorSelect="#intercom" content="Live Help" place="right"/>
                                        </Link>
                                    </li>
                                    <li className="header-setting-icon header-icon">
                                        <Link to="/settings" className={`nav-link`} id={"settings"}>
                                            <img alt="" src={"/assets/img/setting_gray.svg"}
                                                 className="setting-gray-img"
                                                 style={{width: 24, height: 24}}
                                            />
                                            <img alt="" style={{width: 24, height: 24}}
                                                 src={"/assets/img/setting_blue.svg"}
                                                 className="setting-blue-img" id={"mysettings"}/>
                                            <Tooltip anchorSelect="#mysettings" content="Settings" place="right"/>
                                        </Link>

                                    </li>
                                    <li className="header-profile-icon header-icon undefined" id={"profile1"}
                                        style={{marginBottom: '18px'}}>
                                        <Profile active={activeNav} onLogout={signOut}></Profile>
                                    </li>
                                </ul>
                            </div>
                            <main className="main" id="scrollableDiv"
                                  style={{borderTop: "solid 1px #e1e1e5", paddingLeft: '65px'}}>
                                <Suspense fallback={loadingDiv()}>
                                    <Outlet></Outlet>
                                </Suspense>
                            </main>
                        </div>
                    </BehaviourContextProvider>
                </ObjectContextProvider>
            </CategoryContextProvider>
        </div>
    );
};
DefaultLayout.propTypes = propTypes;
DefaultLayout.defaultProps = defaultProps;
export default DefaultLayout;
